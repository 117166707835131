import React from 'react'
import styled from 'styled-components'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import '../styles/variables.css'
import '../styles/global.css'
import Seo from '../components/seo'
import Header from '../components/UI/Header'

/*
  For message banner, please do not delete.
 */ 
// import Banner from '../components/blocks/Banner'

const WithoutFooterLayout = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: 'QuicksandBold',
    },
    palette: {
      primary: {
        main: '#264B65',
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '3px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontFamily: 'Quicksand',
            color: '#264B65',
            '&.Mui-selected': {
              fontFamily: 'QuicksandBold',
              color: '#264B65',
            },
            fontSize: '16px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'QuicksandSemiBold',
            fontsize: '13px',
            color: '#264b65',
            '&:hover': {
              backgroundColor: '#f4f6f7',
              borderRadius: '8px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: '#264b65',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': { color: '#264B65' },
            '& .MuiAccordionSummary-content': { margin: 0 },
          },
        },
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Seo />
      <Header />
      <Main>

        {/* For message banner */}
        {/* <Banner /> */}

        {children}
      </Main>
    </ThemeProvider>
  )
}

// navigation height is set in UI/Header/index.js
const Main = styled.main`
  padding-top: 70px;
  @media screen and (max-width: 991px) {
    padding-top: 64px;
  }

`

export default WithoutFooterLayout
