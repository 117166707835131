import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import ContentContainer from '../UI/ContentContainer'

const Spend1Save1Closed = () => {
	const imageWidth = 409
	return (
		<ContentContainer>
			<Wrapper>
				<Main>
					<MainGetRate>
						<h1>SocietyOne <span className="greenHeader">SpendOne </span><br/>and <span className="greenHeader">SaveOne </span>accounts <br/>will now be closed</h1>
					</MainGetRate>
					<MainImg>
						<StaticImage src='../../assets/images/spendone-saveone-hero2.png' alt="manWithPhone" placeholder='none' loading='eager' width={imageWidth}/>
					</MainImg>
				</Main>
			</Wrapper>
		</ContentContainer>
	)
}

const Wrapper = styled.div`
	position: relative;
	z-index: 1;
	margin: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`
const Main = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}

`
const MainGetRate = styled.div`
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.rate-star-link {
		&:hover {
			color: inherit;
		}
	}
	h1 {
		font-size: 54px;
		margin: 10px 0;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		}
		@media screen and (max-width: 768px) {
			max-width: 400px;
			text-align: center;
			margin-right: auto;
			margin-left: auto;
		}
	};
	.greenHeader {
		color: rgb(29 188 110);
	};
	.max-width-450 {
		max-width: 450px;
		@media screen and (max-width: 768px) {
			margin-right: auto;
			margin-left: auto;
		}
		>div {
			margin: 20px 0;
			font-size: 20px;
			text-align: left;
			@media screen and (max-width: 768px) {
				text-align: center;
			}
		}
	}
`

const MainImg = styled.div`
	justify-self: center;
	@media screen and (max-width: 768px) {
		margin: 50px 0 30px 0;
		width: 60vw;
	};
	@media screen and (max-width: 479px) {
		width: 80vw;
	}
`


export default Spend1Save1Closed