import React from 'react'
import styled from 'styled-components'
import ContentContainer from '../UI/ContentContainer'

const Spend1Save1ClosedBody = () => {

	return (
		<ContentContainer padding="50px 0">
			<ModuleOneContent>
				<h2>You will no longer be able to access them through the App.</h2>
				<p>Thanks again for being a SocietyOne SpendOne and SaveOne customer. From 5 September your accounts will be closed, and we will be disabling the internet banking function from within the SocietyOne App, which means you will no longer be able to access them through the App.</p>
				<p>Please refer to our<a href="https://www.westpac.com.au/about-westpac/innovation/banking-as-a-service/societyone-spendone-saveone/"><strong> FAQs </strong></a>for information on how to access your funds if you have not transferred them before account closure.</p>
			</ModuleOneContent>
			
		</ContentContainer>
	)
}

const ModuleOneContent = styled.div`
	a {
		text-decoration: underline;
	}
	h2 {
		text-align: left; 
		width: 1052px;
		margin-right: auto;
		margin-left: auto;
		@media screen and (max-width: 768px) {
			font-size: 32px;
			width: 100%;
		};
	};
	p {
		text-align: left; 
		font-size: 20px;
		width: 1052px;
		margin-right: auto;
		margin-left: auto;
		@media screen and (max-width: 991px) {
			margin: 10px 0;		
			width: 60vw;
		};
		@media screen and (max-width: 768px) {
			font-size: 18px;
			width: 100%;
		};
	};
`

export default Spend1Save1ClosedBody