import React from 'react'
import styled from 'styled-components'
import ContentContainer from '../UI/ContentContainer'

const TAndC = () => {

	return (
		<ContentContainer padding="80px 0">
			<ModuleOneContent>
				<p>SpendOne and SaveOne accounts are financial products provided by SocietyOne Australia Pty Ltd (ABN 44 151 627 977, Authorised Representative no. 425717 of SocietyOne Investment Management Pty Ltd ACN 604 960 018 AFSL 477365). Accounts and debit cards are issued by Westpac Banking Corporation (ABN 33 007 457 141, AFSL 233714). Any advice has been prepared without taking into account your objectives, financial situation or needs.  So before acting on the advice, you should consider whether it’s appropriate to you, in light of your objectives, financial situation or needs.</p>
			</ModuleOneContent>
			
		</ContentContainer>
	)
}

const ModuleOneContent = styled.div`
	p {
		text-align: left; 
		font-size: 14px;
		width: 1052px;
		margin-right: auto;
		margin-left: auto;
		@media screen and (max-width: 991px) {
			margin: 10px 0;		
			width: 60vw;
		};
		@media screen and (max-width: 768px) {
			width: 100%;
		};
	};
`

export default TAndC